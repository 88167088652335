import React from "react"
import { Box, BoxProps, Image } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"
import { HandleTrackingClickType } from "@app/hoc/Section"

type Props = {
  brand: GatsbyTypes.SanityBrand
  handleTrackingClick: HandleTrackingClickType
}
const BrandItem: React.FC<Props> = ({ brand: { link, image }, handleTrackingClick }) => {
  const { getGatsbyImage } = useImage()
  const { urlResolver } = useRoutes()
  const customLink = urlResolver(link)
  const customImage = getGatsbyImage(image)

  if (!customImage) return null

  const boxStyles: BoxProps = {
    pos: "relative",
    w: { base: "26", lg: "43.5" },
    h: { base: "15", lg: "30" },
  }

  const ImageEl = (
    <Image {...customImage} mixBlendMode="multiply" pos="absolute" top="0" w="full" h="full" objectFit="cover" userSelect="none" />
  )

  return link ? (
    <Box as={Link} to={customLink.url} external={customLink.external} onClick={handleTrackingClick} {...boxStyles}>
      {ImageEl}
    </Box>
  ) : (
    <Box {...boxStyles}>{ImageEl}</Box>
  )
}

export default React.memo(BrandItem)
